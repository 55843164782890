import { Injectable, computed, signal } from "@angular/core";
import { PolicyTransaction } from "../models/policy";
import { uniq } from "lodash";

@Injectable({
  providedIn: "root",
})
export class PolicyTransactionStoreService {
  #active = signal<PolicyTransaction | undefined>(undefined); // active policy transaction
  #history = signal<PolicyTransaction[]>([]); // complete transaction history
  #transactions = signal<PolicyTransaction[]>([]); // transactions loaded into the system

  // policy groups
  #groups = computed(() => {
    const history = this.#history();
    // determine unique group numbers
    const groupNumbers = uniq(
      history.map((transaction) => transaction.group.number),
    );
    // map group information for each unique group number
    return groupNumbers.map(
      (groupNumber) =>
        history.find((transaction) => transaction.group.number === groupNumber)!
          .group,
    );
  });

  // latest policy group by expiration date
  latestPolicyGroup = computed(() => {
    const groups = this.#groups();
    if (!groups.length) {
      return undefined;
    }
    // sort descending by expiration date
    const sorted = [...groups].sort(
      (p1, p2) => p2.expiration.toMillis() - p1.expiration.toMillis(),
    );
    // result in latest policy group by expiration date
    return sorted[0];
  });

  // bound transactions within the latest policy group
  readonly boundTransactions = computed(() => {
    const transactions = this.#history();
    const latestPolicyGroup = this.latestPolicyGroup();
    return transactions.filter(
      (transaction) =>
        transaction.group.number === latestPolicyGroup?.number &&
        ["submission", "renewal"].includes(transaction.type) &&
        transaction.isBound,
    );
  });

  public readonly transactions = this.#transactions.asReadonly();
  public readonly hasTransactions = computed(
    () => this.transactions().length > 0,
  );
  public readonly hasBoundTransaction = computed(
    () =>
      this.#history().filter((transaction) => transaction.isBound).length > 0,
  );

  readonly active = this.#active.asReadonly();

  private readonly _activePolicyTransactionId = signal<string | undefined>(
    undefined,
  );
  readonly activePolicyTransactionId = computed(() => {
    const activeTransaction = this.active();
    const activePolicyTransactionId = this._activePolicyTransactionId();
    return activeTransaction?.id ?? activePolicyTransactionId;
  });

  // Policy products added to account
  public readonly products = computed(() =>
    this.transactions().map((transaction) => transaction.product),
  );

  setPolicyTransactionHistory(transactions: PolicyTransaction[]) {
    this.#history.set(transactions);
  }

  setPolicyTransactions(transactions: PolicyTransaction[]) {
    this.#transactions.set(transactions);
  }

  addPolicyTransactions(transactions: PolicyTransaction[]) {
    this.#history.update((current) => [...current, ...transactions]);
    this.#transactions.update((current) => [...current, ...transactions]);
  }

  // store the transaction as the currently active policy transaction
  activate(transaction: PolicyTransaction) {
    this.#active.set(transaction);
  }

  // remove the transaction as the currently active policy transaction
  deactivate(transaction: PolicyTransaction) {
    const current = this.#active();
    if (current && current.id === transaction.id) {
      this.#active.set(undefined);
    }
  }

  // set the active policy transaction id from legacy
  setActivePolicyTransactionId(policyTransactionId: string) {
    this._activePolicyTransactionId.set(policyTransactionId);
  }

  // clear the legacy active policy transaction id
  clearActivePolicyTransactionId(policyTransactionId: string) {
    const current = this._activePolicyTransactionId();
    if (current && current === policyTransactionId) {
      this._activePolicyTransactionId.set(undefined);
    }
  }
}
