import { Injectable, inject } from "@angular/core";
import { LegacyAccountNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/common/navigation/account-navigation.service";
import { AccountNavigationService } from "./account-navigation.service";
import { Params } from "@angular/router";
import {
  Page,
  PageUpgradeService,
} from "@cq/app/core/environment/services/page-upgrade.service";
import {
  LegacyNavigationTarget,
  NavigationTarget,
} from "@cq/app/core/navigation/models/target";
import { AccountStoreService } from "../stores/account-store.service";

export function legacyAccountNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyAccountNavigationService>("CQAccountNavigation");
}

export const legacyAccountNavigationProvider = {
  provide: LegacyAccountNavigationService,
  useFactory: legacyAccountNavigationFactory,
  deps: ["$injector"],
};

interface AccountInformationNavigationOptions {
  openAddressModalOnLoad?: boolean;
  openNamedInsuredModalOnLoad?: boolean;
  params?: Params;
  reload?: boolean;
  reloadAccount?: boolean;
  allowDeactivation?: boolean;
}

interface AccountQuestionsNavigationOptions {
  openFarmBureau?: boolean;
}

interface AccountAddProductsOptions {
  reload?: boolean;
  reloadAccount?: boolean;
  allowDeactivation?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class AccountNavigationRouterService {
  private readonly legacyAccountNavigation = inject(
    LegacyAccountNavigationService,
  );
  private readonly accountNavigation = inject(AccountNavigationService);
  private readonly accountStore = inject(AccountStoreService);
  private readonly pageUpgrade = inject(PageUpgradeService);

  isAccountActive() {
    return (
      this.legacyAccountNavigation.isAccountActive() ||
      this.accountNavigation.isAccountActive()
    );
  }

  isAccountAddProductsActive() {
    return (
      this.legacyAccountNavigation.isAccountAddProductsActive() ||
      this.accountNavigation.isAccountAddProductsActive()
    );
  }

  isAccountInformationActive() {
    return (
      this.legacyAccountNavigation.isAccountInformationActive() ||
      this.accountNavigation.isAccountInformationActive()
    );
  }

  isAccountQuestionsActive() {
    return (
      this.legacyAccountNavigation.isAccountQuestionsActive() ||
      this.accountNavigation.isAccountQuestionsActive()
    );
  }

  isAccountQuestions(target: NavigationTarget) {
    if (target instanceof LegacyNavigationTarget) {
      return this.legacyAccountNavigation.isAccountQuestions(target.state);
    } else {
      return this.accountNavigation.isAccountQuestions(target.snapshot);
    }
  }

  navigateToAccountInformation(
    accountNumber?: string,
    options?: AccountInformationNavigationOptions,
  ) {
    const account = this.accountStore.account();
    const isFarmAccountNumber = accountNumber?.startsWith("FPK") ?? false;
    const isFarmAccountStored = account?.type === "farm";
    const isAccountTypeParamFarm =
      !accountNumber && !account && options?.params?.accountType === "farm";
    const isFarmAccount =
      isFarmAccountNumber || isFarmAccountStored || isAccountTypeParamFarm;

    if (
      !isFarmAccount &&
      this.pageUpgrade.isUpgraded(Page.ACCOUNT_INFORMATION)
    ) {
      return this.accountNavigation.navigateToAccountInformation(
        accountNumber,
        options?.params,
        {
          allowDeactivation: options?.allowDeactivation,
          openAddAddress: options?.openAddressModalOnLoad,
          openAddNamedInsured: options?.openNamedInsuredModalOnLoad,
        },
      );
    } else {
      return this.legacyAccountNavigation.navigateToAccountInformation(
        accountNumber,
        options,
      );
    }
  }

  navigateToAccountQuestions(
    accountNumber: string,
    options?: AccountQuestionsNavigationOptions,
  ) {
    if (this.pageUpgrade.isUpgraded(Page.ACCOUNT_QUESTIONS)) {
      return this.accountNavigation.navigateToAccountQuestions(accountNumber);
    } else {
      return this.legacyAccountNavigation.navigateToAccountQuestions(
        accountNumber,
        options,
      );
    }
  }

  navigateToAddProducts(
    accountNumber: string,
    options?: AccountAddProductsOptions,
  ) {
    if (this.pageUpgrade.isUpgraded(Page.ACCOUNT_ADD_PRODUCTS)) {
      return this.accountNavigation.navigateToAccountAddProducts(accountNumber);
    } else {
      return this.legacyAccountNavigation.navigateToAddProducts(
        accountNumber,
        options,
      );
    }
  }
}
